import React, { ReactElement } from "react";
import MainBanner from "@components/gethomeprotection/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import GetHomeProtectionWrapper from "@components/layouts/gethomeprotectionWrapper";
import WhatIsHomeWarranty from "@components/gethomeprotection/whatIsHomeWarranty";
import OffersList from "@components/shared/offersList";
import NewJerseyDisclaimer from "@components/shared/newJerseyDisclaimer";
const WhyUS = dynamic(() => import("@components/gethomeprotection/whyUs"));
import HowIsDifferent from "@components/gethomeprotection/howIsDifferent";
import WhoIsHomeWarranty from "@components/gethomeprotection/whoIsHomeWarranty";
export default function GetHomeProtectionHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <GetHomeProtectionWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhatIsHomeWarranty />
            <HowIsDifferent />
            <WhoIsHomeWarranty />
            <WhyUS />
            <NewJerseyDisclaimer backGround="#fff6f1" />
        </GetHomeProtectionWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
